import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  createCampaignOrder,
  getCampaignOrderData,
  getCampaignOrderDetail,
} from "../service/service";
import Header from "../../../common/header/Header";
import PaymentStatusCard from "./PaymentStatusCard.jsx";
const CampaignResultPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();

  let pl_campaign_id = searchParams.get("pl_campaign_id");
  let request_id = searchParams.get("request_id");
  let pl_order_id = searchParams.get("pl_order_id");
  let pl_attempt_id = searchParams.get("pl_attempt_id");
  let pa_txn_id = searchParams.get("pa_txn_id");
  let status = searchParams.get("status");
  let error_code = searchParams.get("error_code");
  let error_message = searchParams.get("error_message");
  let pa_txn_date = searchParams.get("pa_txn_date");
  let txn_amount = searchParams.get("txn_amount");
  
  const [paymentData,setPaymentData]=useState("");
  useEffect(() => {
    getCampaignOrderData(pl_campaign_id,request_id,setPaymentData)
  }, [])
  
  return (
    <>
      {paymentData && (
        <div className="campaign_payment">
          <div className="page_wrapper">
            <div className="header-wrapper">
              <Header />
              <div className="header_bottom">
                <div className="header-bottom-contain">
                  <div className="header_bottom_text">
                    Aditya Birla Sun Life Insurance Company Limited
                  </div>
                </div>
              </div>
            </div>
            <div className="payment-container">
              <div className="name-wrapper ">
                <h3>Welcome,</h3>
                <h5>{paymentData && paymentData.customer_name}</h5>
              </div>
              <div className="details-wrapper">
                <div className="details-header">
                  <h4>Summary</h4>
                  <h3>Policy: {paymentData.product_id}</h3>
                </div>
                <div className="details-content">
                  <div className="row">
                    {paymentData && (
                      <>
                        <div className="col-md-3 col-12">
                          <div className="payment_entity">
                            <span>Policy Owner Name</span>
                            <h6>{paymentData.customer_name}</h6>
                          </div>
                        </div>
                        <div className="col-md-3 col-12">
                          <div className="payment_entity">
                            <span>Email Id</span>
                            <h6>{paymentData.customer_email}</h6>
                          </div>
                        </div>
                        <div className="col-md-3 col-12">
                          <div className="payment_entity">
                            <span>Mobile No.</span>
                            <h6>{paymentData.customer_phone}</h6>
                          </div>
                        </div>
                        <div className="col-md-3 col-12">
                          <div className="payment_entity">
                            <span>Amount</span>
                            <h6>{paymentData.amount}</h6>
                          </div>
                        </div>
                        <div className="col-md-3 col-12 mt-3">
                          <div className="payment_entity">
                            <span>Policy No.</span>
                            <h6>{paymentData.product_id}</h6>
                          </div>
                        </div>
                        
                      </>
                    )}
                  </div>
                </div>
              </div>

              <PaymentStatusCard orderStatus={status} pa_txn_id={pa_txn_id} txn_amount={txn_amount} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CampaignResultPage;
