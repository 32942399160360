import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  createCampaignOrder,
  getCampaignOrderDetail,
} from "../service/service";
import Header from "../../../common/header/Header";
import PaymentStatusCard from "./PaymentStatusCard.jsx";

const CampaignPage = () => {
  let { enc_data } = useParams();
  const [paymentData, setPaymentData] = useState();
  const [attemptData, setAttemptData] = useState();
  useEffect(() => {
    getCampaignOrderDetail(enc_data, paymentData, setPaymentData, setAttemptData);
  }, []);
  return (
    <>


      {
        paymentData && (

          <div className="campaign_payment">
            <div className="page_wrapper">
              <div className="header-wrapper">
                <Header />
                <div className="header_bottom">
                  <div className="header-bottom-contain">
                    <div className="header_bottom_text">
                      Aditya Birla Sun Life Insurance Company Limited
                    </div>
                  </div>
                </div>
              </div>
              <div className="payment-container">
                <div className="name-wrapper ">
                  <h3>Welcome,</h3>
                  <h5>{paymentData && paymentData.data.customer_name}</h5>
                </div>
                <div className="details-wrapper">
                  <div className="details-header">
                    <h4>Summary</h4>
                    <h3>Policy: {paymentData.data.product_id}</h3>
                  </div>
                  <div className="details-content">
                    <div className="row">
                      {paymentData && (

                        <>
                          <div className="col-md-3 col-12">
                            <div className="payment_entity">
                              <span>Policy Owner Name</span>
                              <h6>{paymentData.data.customer_name}</h6>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="payment_entity">
                              <span>Email Id</span>
                              <h6>{paymentData.data.customer_email}</h6>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="payment_entity">
                              <span>Mobile No.</span>
                              <h6>{paymentData.data.customer_phone}</h6>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="payment_entity">
                              <span>Amount</span>
                              <h6>{paymentData.data.amount}</h6>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="payment_entity">
                              <span>Policy No.</span>
                              <h6>{paymentData.data.product_id}</h6>
                            </div>
                          </div>
                          {(paymentData.success && (paymentData?.plOrderData?.length <= 1 || typeof paymentData?.plOrderData === 'undefined')) &&

                            <div className="col-12 mt-3">
                              <button className="pay-btn"
                                onClick={() => {
                                  createCampaignOrder(enc_data);
                                }}
                              >
                                Proceed to Pay
                              </button>
                            </div>
                          }

                        </>
                      )}
                    </div>
                  </div>
                </div>

                {/* <PaymentStatusCard orderStatus={paymentData?.success}/>  */}
                {paymentData?.plOrderData ? <PaymentStatusCard orderStatus={paymentData?.plOrderData?.status} pa_txn_id={paymentData?.plOrderData?.current_attempt_id} txn_amount={paymentData?.plOrderData?.amount} paymentData={paymentData} attempData={attemptData} /> : <PaymentStatusCard orderStatus={paymentData?.success} pa_txn_id="" txn_amount="" paymentData={paymentData} attemptData={attemptData} />}
              </div>
            </div>

          </div>
        )
      }


    </>
  );
};

export default CampaignPage;
